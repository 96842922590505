import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const Kids = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className='kids'>
        <div className='kids-overlay'>
          <Styles className='text-white'>
            <Container>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <h3>Page coming soon</h3>
                </Col>
              </Row>
            </Container>
          </Styles>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Kids;

const Styles = styled.div`
  padding-top: 150px;
`;
