import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Container, Row, Col, Alert } from 'react-bootstrap';
import { db } from '../services/firebase';
import Footer from '../components/Footer';

const ContactForm = () => {
  const [contact, setContact] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });
  const [contactMessageSent, setContactMessageSent] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  };
  const handleForm = (e) => {
    e.preventDefault();
    setError(false);
    if (
      contact.name.trim() !== '' &&
      contact.email.trim() !== '' &&
      contact.message.trim() !== ''
    ) {
      db.collection('users')
        .add({
          name: contact.name,
          email: contact.email,
          mobile: contact.mobile,
          message: contact.message,
        })
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });
      setContact({
        ...contact,
        name: '',
        email: '',
        message: '',
        mobile: '',
      });

      setContactMessageSent(true);
      setTimeout(() => {
        setContactMessageSent(false);
      }, 4000);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <Styles>
        <Container className='py-5'>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              {contactMessageSent && (
                <Alert variant='success'>
                  <button
                    onClick={() => setContactMessageSent(false)}
                    type='button'
                    className='close'
                    aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  <p className='mt-3'>
                    Thank you for your message, a member of our team will be in
                    touch very soon
                  </p>
                </Alert>
              )}

              <Form onSubmit={handleForm} className='bg-info py-3 px-4'>
                {error && (
                  <Alert variant='danger'>Field marked * required! </Alert>
                )}
                <h5 className='mb-3'>
                  Please fill out the form to contact us.
                </h5>
                <Form.Group>
                  <Form.Label>
                    <i className='far fa-user mr-2'></i>
                    Name <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    value={contact.name}
                    name='name'
                    onChange={handleChange}
                    type='text'
                  />
                </Form.Group>
                <Form.Group>
                  <i className='far fa-envelope mr-2'></i>
                  <Form.Label>
                    Email address <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    value={contact.email}
                    name='email'
                    onChange={handleChange}
                    type='email'
                  />
                </Form.Group>
                <Form.Group>
                  <i className='fas fa-phone mr-2'></i>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    value={contact.mobile}
                    name='mobile'
                    onChange={handleChange}
                    type='number'
                  />
                </Form.Group>
                <Form.Group>
                  <i className='fas fa-pencil-alt mr-2'></i>
                  <Form.Label>
                    Message <span className='text-danger'>*</span>
                  </Form.Label>
                  <Form.Control
                    value={contact.message}
                    name='message'
                    onChange={handleChange}
                    as='textarea'
                  />
                </Form.Group>
                <button type='submit' className='btn btn-secondary btn-block'>
                  Submit
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Styles>
      <Footer />
    </React.Fragment>
  );
};

export default ContactForm;

const Styles = styled.div`
  padding-top: 100px;
  form {
    border-radius: 10px;
  }
`;
