import React, { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import Carousel from "react-bootstrap/Carousel"
import { fathers_dayImgs } from "../data"
import styled from "styled-components"
import carousel1 from "../assets/imageGallery/carousel/carousel1.jpg"
import carousel2 from "../assets/imageGallery/carousel/carousel2.jpg"
import carousel3 from "../assets/imageGallery/carousel/carousel3.jpg"
import carousel4 from "../assets/imageGallery/carousel/carousel4.jpg"
import carousel5 from "../assets/imageGallery/carousel/carousel5.jpg"
import carousel6 from "../assets/imageGallery/carousel/carousel6.jpg"
import carousel7 from "../assets/imageGallery/carousel/carousel7.jpg"
import carousel8 from "../assets/imageGallery/carousel/carousel8.jpg"
import carousel9 from "../assets/imageGallery/carousel/carousel9.jpg"
import carousel10 from "../assets/imageGallery/carousel/carousel10.jpg"
import carousel11 from "../assets/imageGallery/carousel/carousel11.jpg"
import img1 from "../assets/imageGallery/img1.jpg"
import img2 from "../assets/imageGallery/img2.jpg"
import img3 from "../assets/imageGallery/img3.jpg"
import img4 from "../assets/imageGallery/img4.jpg"
import img5 from "../assets/imageGallery/img5.jpg"
import img6 from "../assets/imageGallery/img6.jpg"
import img7 from "../assets/imageGallery/img7.jpg"
import img8 from "../assets/imageGallery/img8.jpg"
import img9 from "../assets/imageGallery/img9.jpg"
import img10 from "../assets/imageGallery/img10.jpg"
import img11 from "../assets/imageGallery/img11.jpg"
import img12 from "../assets/imageGallery/img12.jpg"
import Footer from "../components/Footer"
import FathersDay from "../components/FathersDay"

const picLists = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
]

const PhotoGallery = () => {
  const [gallery] = useState(picLists)
  const [fathersDayImages] = useState(fathers_dayImgs)

  console.log(fathersDayImages[0].url)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <React.Fragment>
      <Container>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 offset-md-2 mb-5'>
              <Carousel interval={3000}>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel1} alt={carousel1} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel2} alt={carousel2} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel3} alt={carousel3} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel4} alt={carousel4} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel5} alt={carousel5} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel6} alt={carousel6} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel7} alt={carousel7} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel8} alt={carousel8} />
                </Carousel.Item>
                <Carousel.Item>
                  <img className='img-fluid' src={carousel9} alt={carousel9} />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='img-fluid'
                    src={carousel10}
                    alt={carousel10}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className='img-fluid'
                    src={carousel11}
                    alt={carousel11}
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          <div>
            <FathersDay />
          </div>
          <div className='row'>
            {gallery.map((photo) => {
              return (
                <div className='col-md-6' key={uuid()}>
                  <img
                    loading='lazy'
                    className='img-fluid mb-4'
                    src={photo}
                    alt='Church activities'
                  />
                </div>
              )
            })}
          </div>
          <div className='text-center'>
            <a
              className=' btn btn-more m-5 font-weight-bold'
              href='https://www.facebook.com/bpci.andover.9/photos_all'
              target='_blank'
              rel='noopener noreferrer'>
              BROWSE ALL PHOTOS <i className='fas fa-chevron-right ml-1'></i>
            </a>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default PhotoGallery

const Container = styled.div`
  padding-top: 150px;
  .btn-more {
    padding: 8px 20px;
    border: 1px solid black;
    letter-spacing: 1.5px;
    &:hover {
      color: #fff;
      background: black;
    }
  }
`
