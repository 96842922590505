import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const Babtism = () => {
  return (
    <React.Fragment>
      <Styles>
        <Container>
          <h5>Babtism page coming soon!</h5>
        </Container>
      </Styles>
    </React.Fragment>
  );
};

export default Babtism;

const Styles = styled.div`
  padding-top: 150px;
`;
