import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import prempeh from '../assets/img/prempeh.jpg';
import pstalexander from '../assets/img/pstalexander.jpg';
import Footer from '../components/Footer';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <AboutWrapper>
        <div className='our-purpose'>
          <Container className='py-5 text-center'>
            <h5 className='text-white mb-3 '>Our Purpose</h5>
            <h3>
              To win souls for the kingdom of God and shape the destiny of
              mankind.
            </h3>
          </Container>
        </div>
        <div className='our-vision py-5'>
          <Container className='text-center'>
            <h5 className='text-white mb-3 '>Our Vision</h5>
            <h3>
              For people to know God, find peace, discover their purpose and
              impact the world.
            </h3>
          </Container>
        </div>
        <div className='container pastors py-5'>
          <div className='row'>
            <div className='col-md-5 mb-5'>
              <img
                className='general__pastor '
                src={pstalexander}
                alt='Pa Prempeh'
              />
            </div>
            <div className='col-md-7  text-secondary px-5 pastor__bio'>
              <h5 className='font-weight-bold'>Our General Overseer</h5>
              <h4 className='font-weight-bold'>Prophet Rev. Alex Owusu</h4>
              <p>
                Prophet Alex Owusu is a respected Christian statesman, a
                renowned Prophet, deep thinker. He overseers the multi-faceted
                network of ministries of Breakthrough Prayer Chapel
                International with headquarters in Accra, Dansoman where he
                serves as the Senior Pastor. Prophet Alex Owusu is a man whose
                prophetic declarations cuts through the spiritual realms and
                brings liberty and deliverance to the children of God,
                extricating them from bondage and darkness and with the help of
                God relocates them in the divine purpose of God.
              </p>
              <p>
                He is a great spiritual and secular motivational speaker and has
                a big dream for the children of God. He dreams of leading a
                crusade where the hurting, the depressed, the frustrated and the
                confused can find love, acceptance, help, hope forgiveness,
                guidance and encouragement. He hopes to achieve this by coaching
                people to spiritual maturity through bible teachings, seminars,
                retreats, and bible school.
              </p>
              <p>
                His passion to see the body of Christ grow has led to a
                programme which he organizes yearly for Pastors, Church Leaders
                and workers dubbed “DEEP CALLETH DEEP” Conference. This yearly
                programme has been a blessing to the body of Christ and had
                featured prominent and distinguished ministers of the gospel.
                Prophet Alex Owusu is also a board member of the National
                Association of Charismatic and Christian Churches in Ghana
                (NACCC) led by Archbishop Nicholas Duncan Williams of Ghana.
              </p>
              <p>
                He is married to Lady Rev. Regina Frema Owusu the founder and
                the president of the ‘Virtuous Ladies Ministry’ of Breakthrough
                Prayer Chapel International a ministry that helps single and
                unmarried ladies to maximize their singleness to enable them
                choose rightful life partners. His marriage is blessed with
                wonderful children, two boys and a girl namely, Herbert, Robert
                and Phoebe Owusu respectively.
              </p>
            </div>
          </div>
          <div className='row py-5'>
            <div className='col-md-5 mb-5'>
              <img
                className='resident__pastor'
                src={prempeh}
                alt='Pa Prempeh'
              />
            </div>
            <div className='col-md-7 text-secondary px-5 pastor__bio'>
              <h5 className='font-weight-bold'>Our Resident Pastor</h5>
              <h4 className='font-weight-bold'> PS. E.A PREMPEH</h4>
              <p>
                Pastor Edward A Prempeh is happily married to Lady Mrs Stephanie
                A Prempeh and they are blessed with three (3) wonderful
                children.
              </p>
              <p>
                He is the Resident/Branch Pastor for BPCI in Andover and Stoke,
                and has been involved in ministry work since 2011. He was once a
                the Youth Vice President of BPCI HQ in Accra, Ghana.
              </p>
              <p>
                He holds a B.A (Hons) degree in Sociology from the University of
                Ghana and an experienced Logistic Specialist formerly with the
                British Army.
              </p>
              <p>
                He is passionate about the work of God, preaching the word of
                God, soul winning and impacting the youth with the love of God.
              </p>
            </div>
          </div>
        </div>
        <div className='church-histroy bg-dark'>
          <div className='container py-5 text-center'>
            <h2>Our Church Histroy</h2>
            <p>
              BPCI Andover was founded on 14 August 2016 at Augusta Park
              Community Center
            </p>
            <p>
              We operate with a clear purpose to “win souls for the Kingdom of
              God and shape the destiny of mankind”, engaging with people across
              the community we are located and as far as we can get through our
              life-giving messages and passionate praise and worship in
              multi-generational and multicultural services, helping new people
              to find Jesus every week.
            </p>
            <p>
              We are a WORD and PRAYER based church who believe in the baptism
              of the Holy Ghost and the speaking in tokngues and manifestation
              of all the spiritual gifts in the bible.
            </p>
            <p>
              We annually do charitable donations to organisations here in UK as
              part of our social responsibility and we also collect charitable
              donations from the general public in various forms (clothings,
              medical supplies, cash) etc to support our missions work in
              Ghana,West Africa.
            </p>
          </div>
        </div>
        <div className='believe'>
          <div className='container py-5'>
            <div className='believe-content'>
              <h2>What We Believe</h2>
              <p>
                We believe in one eternal God who is entirely limitless and
                exists in three distinct and equal persons: the Father, the Son,
                and the Holy Spirit. The only way to achieve salvation is to
                trust and believe in Jesus Christ, the Son of God. Our purpose
                is to spread God’s salvation message to the whole world and to
                help each other grow spiritually.
              </p>
              <Link className='btn font-weight-bold' to='what-we-believe'>
                MORE INFORMATION
              </Link>
            </div>
          </div>
        </div>
      </AboutWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default About;

const AboutWrapper = styled.div`
  /* letter-spacing: 1.2px; */
  color: #f6f3ee;
  overflow-x: hidden;
  .believe .believe-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50vh;
  }
  .our-purpose {
    background: #1f1f1f;
    padding-top: 130px;
  }
  .our-vision {
    background: #a3a3a3;
  }

  .btn {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    padding: 12px 18px;
    letter-spacing: 3px !important;
    &:hover {
      background: #fff;
      color: black;
    }
  }
  .resident__pastor {
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
  }

  .general__pastor {
    width: 100%;
    border-radius: 15px;
  }
  .pastor__bio {
    align-self: center;
  }
  @media (max-width: 786px) {
    .believe-content {
      height: auto;
    }
    .church-histroy {
      height: auto;
    }
  }
`;
