import React, { createContext, useState } from 'react';

export const ChurchDetailsContext = createContext();
const ChurchDetailsContextProvider = (props) => {
  const [address] = useState({
    firstLine: 'Gilliat Hall',
    town: 'Andover',
    county: 'Hampshire',
    postcode: 'SP10 1DQ',
  });

  const [charityNumber] = useState(1177351);
  const [companyNumber] = useState(663536366);
  const [contactDetails] = useState({
    phone: '07796710314',
    email: 'bpci.uk@yahoo.com',
  });

  const [bankDetails] = useState({
    bank: 'Barclays Bank',
    accountName: 'Breakthrough Prayer Chapel International',
    sortCode: '20-45-45',
    accountNumber: '13992748',
    IBAN: 'GB70BUKB20454513992748',
    Swift: 'BUKBGB22',
  });

  return (
    <ChurchDetailsContext.Provider
      value={{
        address,
        charityNumber,
        companyNumber,
        contactDetails,
        bankDetails,
      }}>
      {props.children}
    </ChurchDetailsContext.Provider>
  );
};

export default ChurchDetailsContextProvider;
