import firebase from 'firebase/app';
import 'firebase/firestore';

var Config = {
  apiKey: 'AIzaSyBd69i8aSEf2UIhfHkX7VwHa_cD0BYI-uo',
  authDomain: 'bpci-church.firebaseapp.com',
  databaseURL: 'https://bpci-church.firebaseio.com',
  projectId: 'bpci-church',
  storageBucket: 'bpci-church.appspot.com',
  messagingSenderId: '798559846272',
  appId: '1:798559846272:web:433603270f805044368b65',
};
// Initialize Firebase
firebase.initializeApp(Config);

export const db = firebase.firestore();
