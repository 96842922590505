import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import styled from 'styled-components';

const Ourbelieve = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <OurBelieveWrapper>
        <div className='what-webelieve  text-center'>
          <div className='container py-6'>
            <h2 className='display-4 font-weight-bold'>
              WHAT WE BELIEVE AS CHURCH
            </h2>
            <p className='lead'>
              More detailed information on what we believe as a Church
            </p>
          </div>
        </div>
        <div className='container py-5'>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT GOD</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                We believe in one God who eternally exists in three distinct
                persons: the Father, the Son, and the Holy Spirit. These three
                are co-equal and are one God. God the Father is the Creator and
                Ruler of the Universe. We believe that He is limitless in power,
                knowledge, wisdom, love, and holiness. We also believe that He
                has revealed himself as Father to those who believe in Him.
              </p>
              <p className='font-italic'>
                Read more: Genesis 1:26-31; 3:1-7, Psalms 8:3-6; Isaiah 53:6a,
                59:1-2; Romans 3:23, 5:12-21
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT JESUS CHRIST</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                We believe that Jesus Christ is the Son of God. He is co-equal
                with the Father and the Holy Spirit. Jesus was born of a virgin,
                had a miraculous ministry, lived a sinless human life and
                offered himself as the perfect sacrifice for the sins of all men
                and women by dying on a cross. He rose from the dead after three
                days and in so doing demonstrated His power over sin and death.
                He ascended to Heaven’s glory where He sits, interceding for His
                people, at the right hand of the Father. He will return again to
                earth to reign as King of Kings, and Lord of Lords.
              </p>
              <p className='font-italic'>
                Read more: Matt 1:22, 23; Isaiah 9:6; John 1:1-5, 14:10-30, 1
                Peter 2:22; Acts 1:9-11, 2:22; 10:38; Romans 1:3,4; 8:34;2
                Corinthians 5:21; Hebrews 4:14-15, 7:25, 9:12; Luke 24:39; 1
                Corinthians 15:3, 4; 15:22-24; 51-57, Revelation 20:1-6.
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT THE HOLY SPIRIT</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                He is present in the world to make men and women aware of their
                need for Jesus Christ. He also lives in every Christian from the
                moment of salvation. The Holy Spirit is co-equal with the Father
                and the Son of God. He provides the Christian with power for
                living, understanding of spiritual truth, and guidance in doing
                what is right. He gives every believer spiritual gifts as He
                determines for the benefit of other believers and as a witness
                to non-believers. As Christians we seek to live under His
                control daily.
              </p>
              <p className='font-italic'>
                Read More: 2 Corinthians 3:17; John 16:7-13; 14:16,17; Acts 1:8;
                2:4; 10:44-46; 11:14-16; 19:6; Romans 12:4-13; 1 Corinthians
                2:12; 3:16; 12:1-11 Ephesians 1:13; 5:1; Galatians 5:25.
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT THE BIBLE</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                The Bible is God’s Word to all people. It was written by human
                authors, under the supernatural guidance of the Holy Spirit. It
                is the supreme source of truth for Christian beliefs and living.
                Because it is inspired by God, it is the truth without any
                mixture of error.
              </p>
              <p className='font-italic'>
                Read more: 2 Timothy 3:15-17; 2 Peter 1:20,21; Psalm
                119:105,160; Proverbs 30:5
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT SALVATION</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                ABOUT SALVATION We believe that salvation is a gift of God to
                mankind. Men and women can never make up for sin by
                self-improvement or good works. Only by trusting in Jesus Christ
                as God’s offer of forgiveness, through his substitutionary
                sacrifice, can people be saved from sin’s penalty. Eternal life
                begins the moment one receives Jesus Christ into their life by
                faith. This is known as the New Birth, and is an instantaneous
                and complete operation of the Holy Spirit upon initial faith in
                the Lord Jesus Christ.
              </p>
              <p className='font-italic'>
                Read more: Romans 6:23, 10:8-15; Titus 2:11; 3:5-7; 1
                Corinthians 15:3-40; Galatians 3:26, 5:1; John 3:5-6; 14:6;
                Ephesians 2:8,9; James 1:18; 1 Peter 1:23; 1 John 5:1
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-4'>
              <h5 className='font-weight-bold'>ABOUT ETERNITY</h5>
            </div>
            <div className='col-md-7 text-secondary'>
              <p>
                We believe that people were created to live forever. We will
                either exist eternally separated from God by sin, or eternally
                with God through forgiveness and salvation. To be eternally
                separated from God is to live in Hell. To be eternally in union
                with Him is eternal life. Heaven and Hell are real places of
                eternal existence.
              </p>
              <p className='font-italic'>
                Read more: Matthew 25:41,46; John 3:16, 5:28-29; Romans 6:23;
                Hebrews 10:14; 1 Peter 1:3-5; Revelation 20:15, 21:1-5;
              </p>
            </div>
          </div>
        </div>
      </OurBelieveWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Ourbelieve;

const OurBelieveWrapper = styled.div`
  .what-webelieve {
    background: #6f4c6f;
    letter-spacing: 1px;
    color: #e5e9e6;
  }
`;
