import React, { useEffect, useContext } from 'react';
import { ChurchDetailsContext } from '../context/ChurchDetailsContext';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';

const Contact = () => {
  const { address, contactDetails, charityNumber } = useContext(
    ChurchDetailsContext
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>
      <ContactWrapper className='contact text-center'>
        <Container>
          <h5 className='font-weight-bold display-4 get__intouch'>
            GET IN TOUCH
          </h5>
          <p>
            We’re always here to answer any questions you have, big or small
            about our Church or joining our community. Contact us today.
          </p>
          <div className='row'>
            <div className='col-md-3 offset-md-4'>
              <p className='address lead py-3'>
                {address.firstLine} <br />
                {address.town} <br />
                {address.county} <br />
                {address.postcode}
              </p>
            </div>
          </div>
          <div className='telephone mt-4'>
            <p>
              Telephone | {contactDetails.phone} <br />
              Email |
              <a
                className='contact-email'
                href={`mailto:${contactDetails.email}`}>
                {contactDetails.email}
              </a>{' '}
              <br />
              BPCI is a Charismatic Church and a Registered Charity No.{' '}
              {charityNumber}
            </p>
            {/* <p className='p3-5'>
              You can also contact us by clicking the button below and filling
              out the form.
            </p>
            <Link to='contact-form' className='btn btn-secondary mb-5'>
              Contact Us
            </Link> */}
          </div>
        </Container>
      </ContactWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;

const ContactWrapper = styled.div`
  color: #dddad6;
  padding-top: 200px;
  .contact-email {
    text-decoration: underline;
  }

  .address {
    text-align: center;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    letter-spacing: 1px;
  }

  @media (max-width: 360px) {
    padding-top: 100px;
  }
  .get__intouch {
    font-size: 2rem;
  }
`;
