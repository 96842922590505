export const fathers_dayImgs = [
  { url: "./assets/imageGallery/fathers-day/img01.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img02.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img03.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img04.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img05.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img05.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img06.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img07.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img08.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img09.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img10.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img11.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img12.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img13.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img14.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img15.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img16.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img17.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img18.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img19.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img20.jpeg" },
  { url: "./assets/imageGallery/fathers-day/img21.jpeg" },
]
