import React from "react"
import img1 from "../assets/imageGallery/fathers-day/img01.jpeg"
import img2 from "../assets/imageGallery/fathers-day/img02.jpeg"
import img3 from "../assets/imageGallery/fathers-day/img03.jpeg"
import img4 from "../assets/imageGallery/fathers-day/img04.jpeg"
import img5 from "../assets/imageGallery/fathers-day/img05.jpeg"
import img6 from "../assets/imageGallery/fathers-day/img06.jpeg"
import img7 from "../assets/imageGallery/fathers-day/img07.jpeg"
import img8 from "../assets/imageGallery/fathers-day/img08.jpeg"
import img9 from "../assets/imageGallery/fathers-day/img09.jpeg"
import img10 from "../assets/imageGallery/fathers-day/img10.jpeg"
import img11 from "../assets/imageGallery/fathers-day/img11.jpeg"
import img12 from "../assets/imageGallery/fathers-day/img12.jpeg"
import img13 from "../assets/imageGallery/fathers-day/img13.jpeg"
import img14 from "../assets/imageGallery/fathers-day/img14.jpeg"
import img15 from "../assets/imageGallery/fathers-day/img15.jpeg"
import img16 from "../assets/imageGallery/fathers-day/img16.jpeg"
import img17 from "../assets/imageGallery/fathers-day/img17.jpeg"
import img18 from "../assets/imageGallery/fathers-day/img18.jpeg"
import img19 from "../assets/imageGallery/fathers-day/img19.jpeg"
import img20 from "../assets/imageGallery/fathers-day/img20.jpeg"
import img21 from "../assets/imageGallery/fathers-day/img21.jpeg"
import img22 from "../assets/imageGallery/fathers-day/img22.jpeg"
import { useState } from "react"
import { v4 as uuid } from "uuid"

const FathersDay = () => {
  const [gallery] = useState([
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img10,
    img11,
  ])
  return (
    <div className='mb-5 pt-4'>
      <h2>Fathers' Day 2022</h2>
      <div className='row'>
        {gallery.map((photo) => {
          return (
            <div className='col-md-6' key={uuid()}>
              <img
                loading='lazy'
                className='img-fluid mb-4'
                src={photo}
                alt='Fathers day out'
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FathersDay
