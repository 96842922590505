import React, { useContext } from "react"
import { ChurchDetailsContext } from "../context/ChurchDetailsContext"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import { motion } from "framer-motion"

const Footer = () => {
  const { address, charityNumber, contactDetails } =
    useContext(ChurchDetailsContext)
  const scrollUp = () => {
    window.scrollTo(0, 0)
  }
  return (
    <React.Fragment>
      <Styles className='footer py-2 text-secondary'>
        <Container>
          <i
            onClick={scrollUp}
            className='fas fa-arrow-up d-none d-lg-block page-up m-3'></i>
          <Row className='text-center'>
            <Col md={3} className='mb-4'>
              <i className='fas fa-map-marker-alt mb-4'></i>
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='text-secondary'
                href='https://www.google.com/maps/place/Gilliat+Hall/@51.2116907,-1.483099,17.25z/data=!4m5!3m4!1s0x487407a08514c7ed:0x4fda5dff27bddb2f!8m2!3d51.2113945!4d-1.4810759'>
                <br />
                {address.firstLine}
                <br />
                {address.town}
                <br />
                {address.postcode}
              </a>
            </Col>
            <Col md={3} className='mb-4'>
              <i className='fas fa-phone-alt mb-4'></i>
              <br />
              <a
                className='text-secondary'
                href={`tel:${contactDetails.phone}`}>
                {" "}
                {contactDetails.phone}
              </a>
            </Col>
            <Col md={3} className='mb-4'>
              <i className='fas fa-envelope mb-4'></i>
              <br />
              <a
                className='text-secondary footer-email'
                href={`mailto:${contactDetails.email}`}>
                {contactDetails.email}
              </a>
            </Col>
            <Col md={3} className='mb-4'>
              <i className='fas fa-gavel mb-4'></i>
              <br />
              Charity No. {charityNumber}
              <br />
              <Link to='privacy-policy' className='text-secondary policy-link'>
                PRIVACY POLICY
              </Link>
            </Col>
          </Row>
        </Container>
      </Styles>
      <div className='social-icons'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 offset-md-4'>
              <div className='social-media mb-3'>
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  className='text-secondary'
                  href='https://www.facebook.com/bpci.andover.9'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fab fa-facebook '></i>
                </motion.a>
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  className='text-secondary'
                  href='/'>
                  <i className='fab fa-instagram '></i>
                </motion.a>
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  className='text-secondary'
                  href='/'>
                  <i className='fab fa-youtube '></i>
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-message text-center'>
        <p className='footer-note text-secondary'>&copy; 2021 BPCI Andover</p>
      </div>
    </React.Fragment>
  )
}

export default Footer

const Styles = styled.div`
  .policy-link {
    text-decoration: underline;
    letter-spacing: 1px;
    font-size: 12px;
  }
  position: relative;
  height: auto;
  width: 100%;
  background: #151815;
  font-size: 14px;
  i {
    font-size: 1.5rem;
  }
  .page-up {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 15px;
    &:hover {
      cursor: pointer;
    }
  }

  .footer-email {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`
