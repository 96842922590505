import React from 'react';
import styled from 'styled-components';

const Connect = () => {
  return (
    <React.Fragment>
      <Container>
        <div className='container'>
          <h5>Connect page coming soon</h5>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Connect;

const Container = styled.div`
  padding-top: 150px;
`;
