import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import ChurchDetailsContextProvider from './context/ChurchDetailsContext';

ReactDOM.render(
  <Router>
    {/* <React.StrictMode> */}
    <ChurchDetailsContextProvider>
      <App />
    </ChurchDetailsContextProvider>
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById('root')
);
