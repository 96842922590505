import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

function Covid19update() {
  return (
    <React.Fragment>
      <CovidWrapper>
        <p className='display-4 text-center pb-5 text-white font-weight-bold'>
          COVID-19 UPDATES
        </p>
      </CovidWrapper>
      <div className='container py-5 text-secondary'>
        <div className='row'>
          <div className='col-md-8 offset-md-2'>
            <p>Hi All</p>
            <br />
            <p>
              We are living in unprecedented times, and as the everyday is
              rapidly changing, we want to reassure you that everyone at BPCI is
              devoted to taking care of you. In light of the current global
              coronavirus (COVID-19) situation, we have been keeping up to date
              with advice from the World Health Organisation and the UK
              government.
            </p>
            <p>
              <br />
              As a leadership team, we have been meeting regularly over the last
              few weeks to review all of our efforts in relation to this crisis.
              Our priority has been and continues to be the welfare of our Arena
              family and our wider communities as well as being able to watch
              out for the most vulnerable. We now want to update you on some
              further steps we feel we need to take, which include the
              following:
            </p>

            <br />
            <br />
            <h5>
              <b>Sunday Services</b>
            </h5>
            <p>
              SUNDAY SERVICES From this <b>Sunday 29 March</b> we will be moving
              to online services for the foreseeable future and will not be
              gathering in any of our locations. We’re working hard to deliver a
              great online service for this Sunday. We will have special access
              on our Church Online page for Church online and on demand, Kids
              Church Service, prayer requests, connect cards and giving online –
              keep checking this page for further updates about how to access
              this.
            </p>
          </div>
        </div>
      </div>
      <div className='bg-light'>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-md-8 offset-md-2'>
              <h4>HERE IS WHAT WE CAN DO TO HELP EACH OTHER</h4>
              <p>
                1. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Saepe enim, autem alias, praesentium quasi culpa necessitatibus,
                dolore deleniti voluptate distinctio optio illo quidem provident
                laboriosam minus vel vitae qui ipsum?
              </p>
              <br />
              <p>
                2. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Saepe enim, autem alias, praesentium quasi culpa necessitatibus,
                dolore deleniti voluptate distinctio optio illo quidem provident
                laboriosam minus vel vitae qui ipsum?
              </p>
              <br />
              <p>
                3. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Saepe enim, autem alias, praesentium quasi culpa necessitatibus,
                dolore deleniti voluptate distinctio optio illo quidem provident
                laboriosam minus vel vitae qui ipsum?
              </p>
              <br />
              <br />
              <p className='font-italic'>
                Because you have made the Lord, who is my refuge, even the Most
                High, your dwelling place, no evil shall befall you, nor shall
                any plague come near your dwelling. Psalm 91:9–10
              </p>
              <br />
              <br />
              <p>
                Please keep returning to this page for essential updates. <br />
                With our love and prayers, <br />
                Pastor Prempeh <br />
                Resident Pastor.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Covid19update;

const CovidWrapper = styled.div`
  background: red;
  padding-top: 120px;
`;
