import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <React.Fragment>
      <Styles>
        <Container className='text-center'>
          <h5 className='text-muted font-weight-bold'>404 - Page Not Found</h5>
          <h1>The page you are looking for is not found</h1>
          <p className='text-muted'>
            The page you are looking for does not exist. It may have been
            removed altogether.
          </p>
          <Link className='btn font-weight-bold text-muted' to='/'>
            BACK TO HOMEPAGE
          </Link>
        </Container>
      </Styles>
    </React.Fragment>
  );
};

export default PageNotFound;

const Styles = styled.div`
  height: 85vh;
  margin-top: 100px;
  .btn {
    padding: 8px 16px;
    border: 1px solid gray;
    letter-spacing: 2px;
  }
`;
