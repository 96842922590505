import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { ChurchDetailsContext } from '../context/ChurchDetailsContext';

const Privacypolicy = () => {
  const { charityNumber, address, contactDetails } = useContext(
    ChurchDetailsContext
  );
  const [registerdAddress] = useState({
    firstLine: '61 Hawthorn Avenue',
    town: 'Andover',
    postCode: 'SP11 6YU',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <PolicyWrapper>
        <div className='privacy-policy text-center'>
          <div className='container py-6'>
            <h1 className='display-4 font-weight-bold'>OUR PRIVACY POLICY</h1>
          </div>
        </div>
        <div className='container py-5'>
          <div className='row mb-5'>
            <div className='  col-md-8 offset-md-2'>
              <h4 className='font-weight-bold text-muted'>INTRODUCTION</h4>
              <p className='text-secondary'>
                We consider the privacy of visitors to our website to be
                extremely important. This privacy policy document describes in
                detail the types of personal information is collected and
                recorded by arenachurch.co.uk and how we use it. By using our
                website and agreeing to this policy, you consent to our use of
                cookies in accordance with the terms of this policy.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>
                COLLECTION & USE OF PERSONAL INFORMATION
              </h5>
              <p className='text-secondary'>
                Like many other websites, <a href='/'>bpci.org.uk</a> makes use
                of log files. These files merely log visitors to the site –
                usually a standard procedure for hosting companies and a part of
                hosting services’s analytics. The information inside the log
                files includes internet protocol (IP) addresses, browser type,
                Internet Service Provider (ISP), date/time stamp, referring/exit
                pages, and possibly the number of clicks, your approximate
                geographical location, OS and referral source. This information
                is used to analyze trends, administer the site, track user’s
                movement around the site, personalise the site for you, send
                email newsletters if requested (you can inform us at any time if
                you no longer require the newsletter), and gather demographic
                information. We may provide third parties with statistical
                information about our users but IP addresses and other such
                information are not linked to any information that is personally
                identifiable.
              </p>
              <p className='text-secondary'>
                We may collect, store and use information that you provide to us
                when contacting us through our website or subscribing to email
                notifications/newsletters, potentially including your name and
                email address. We may also use information contained in or
                relating to any communication that you send to us or send
                through our website including the communication content and
                metadata associated with the communication and any other
                personal information that you choose to send to us. Before you
                disclose to us the personal information of another person, you
                must obtain that person’s consent to both the disclosure and the
                processing of that personal information in accordance with this
                policy.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>
                DISCLOSING PERSONAL INFORMATION
              </h5>
              <p className='text-secondary'>
                We may disclose your personal information to any of our
                employees, officers, insurers, professional advisers, agents,
                suppliers or subcontractors insofar as reasonably necessary for
                the purposes set out in this policy. We may disclose your
                personal information to any member of our group of companies
                (this means our subsidiaries, our ultimate holding company and
                all its subsidiaries) insofar as reasonably necessary for the
                purposes set out in this policy.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>
                RETAINING PERSONAL INFORMATION
              </h5>
              <p className='text-secondary'>
                Information that we collect may be stored and processed in and
                transferred between any of the countries in which we operate in
                order to enable us to use the information in accordance with
                this policy.
              </p>
              <p className='text-secondary'>
                Information that we collect may be transferred to the United
                States of America which does not have data protection laws
                equivalent to those in force in the European Economic Area.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>
                SECURITY OF PERSONAL INFORMATION
              </h5>
              <p className='text-secondary'>
                This section sets out our data retention policies and procedure,
                which are designed to help ensure that we comply with our legal
                obligations in relation to the retention and deletion of
                personal information. • Personal information that we process for
                any purpose or purposes shall not be kept for longer than is
                necessary for that purpose or those purposes Notwithstanding the
                above provisions, we will retain documents (including electronic
                documents) containing personal data:
                <li>To the extent that we are required to do so by law</li>
                <li>
                  If we believe that the documents may be relevant to any
                  ongoing or prospective legal proceedings
                </li>
                <li>
                  In order to establish, exercise or defend our legal rights
                  (including providing information to others for the purposes of
                  fraud prevention and reducing credit risk).
                </li>
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>COOKIE POLICY</h5>
              <p className='text-secondary'>
                This site uses cookies – small text files that are placed on
                your machine to help the site provide a better user experience.
                In general, cookies are used to retain user preferences, store
                information for things like shopping carts, and provide
                anonymised tracking data to third party applications like Google
                Analytics. As a rule, cookies will make your browsing experience
                better and do not typically contain any information that
                personally identifies a user, but personal information that we
                store about you may be linked to the information stored in and
                obtained from cookies. However, you may prefer to disable
                cookies on this site and on others. The most effective way to do
                this is to disable cookies in your browser. We suggest
                consulting the Help section of your browser or taking a look at
                the About Cookies website{' '}
                <a href='http://www.aboutcookies.org'>
                  http://www.aboutcookies.org
                </a>{' '}
                which offers guidance for all modern browsers.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>YOUR RIGHTS</h5>
              <p className='text-secondary'>
                You may instruct us to provide you with any personal information
                we hold about you; provision of such information will be subject
                to:
                <li>the payment of a fee (currently fixed at GBP 10)</li>
                <li>
                  the supply of appropriate evidence of your identity (for this
                  purpose, we will usually accept a photocopy of your passport
                  certified by a solicitor or bank plus an original copy of a
                  utility bill showing your current address)
                </li>
              </p>
              <p className='text-secondary'>
                We may withhold personal information that you request to the
                extent permitted by law.
              </p>
              <p className='text-secondary'>
                You may instruct us at any time not to process your personal
                information for marketing purposes. You may also instruct us to
                delete any information held on you, present and historical (“The
                Right to be Forgotten”)
              </p>
              <p className='text-secondary'>
                In practice, you will usually either expressly agree in advance
                to our use of your personal information for marketing purposes,
                or we will provide you with an opportunity to opt out of the use
                of your personal information for marketing purposes.
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-8 offset-md-2'>
              <h5 className='font-weight-bold text-muted'>OUR DETAILS</h5>
              <p className='text-secondary'>
                Breakthrough Prayer Chapel International is registered in
                [England and Wales] as a charity under number{' '}
                <span className='font-weight-bold'> {charityNumber} </span>
                and is governed by a trust deed dated 28 February, 2018. Our
                registered office is at{' '}
                <span className='font-weight-bold'>
                  {registerdAddress.firstLine}, {registerdAddress.town}{' '}
                  {registerdAddress.postCode}
                </span>
                . Our principal place of business is at{' '}
                <span className='font-weight-bold'>
                  {address.firstLine} {address.town} {address.county}{' '}
                  {address.postcode}.
                </span>{' '}
                You can contact us on:
                <br />
                • by post, using the postal address given above
                <br />
                . by contact form using our website contact form <br />. by
                telephone on {contactDetails.phone}
                <br />• by email, using {contactDetails.email}
              </p>
            </div>
          </div>
        </div>
      </PolicyWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Privacypolicy;

const PolicyWrapper = styled.div`
  .privacy-policy {
    background: #6f4c6f;
    letter-spacing: 1.5px;
    color: #e5e9e6;
  }
`;
