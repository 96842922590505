import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

const ChurchOnline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className='church-online'>
        <div className=' church-online-content text-white'>
          <h1 className='display-4 font-weight-bold'>CHURCH</h1>
          <h1 className='display-4 ml-5 font-italic font-weight-light'>
            ONLINE
          </h1>
          <h5>Join us every Sunday from:</h5>
          <h3 className=' font-italic font-weight-bold'>9am</h3>
          <a
            href='https://join.freeconferencecall.com/prempeh55'
            target='_blank'
            rel='noopener noreferrer'>
            <BUTTON className='mt-3 btn font-weight-bold'>
              JOIN CHURCH ONLINE
            </BUTTON>
          </a>
        </div>
      </div>
      <Contact className='contact-us py-5 text-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 offset-md-3'>
              <h5>
                If you’re new to BPCI, and want to make the decision today,
                click on the link below so we can connect with you and help you
                on your next steps.
              </h5>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://docs.google.com/forms/d/e/1FAIpQLSdu4sYpEqCxWKe2vKTKU0GDlhbr3SFpZ1VF4FRkoiTx5QLVdg/viewform'>
                <button className='btn btn-outline-secondary btn-hover-black mt-3'>
                  NEW TO BPCI
                </button>
              </a>
            </div>
          </div>
        </div>
      </Contact>
      <Footer />
    </React.Fragment>
  );
};

export default ChurchOnline;

const BUTTON = styled.button`
  padding: 12px 19px;
  color: #fff;
  border: 1px solid #fff;
  letter-spacing: 2px;
  background: black;
  &:hover {
    color: black;
    background: #fff;
  }
`;

const Contact = styled.div`
  background: #eaeefa;
`;
